import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponseInterface } from '@src/app/core/interfaces/api-response.interface';

import { HttpService } from '@src/app/core/services/http.service';

import { JOB_DETAIL_BASE_URL } from '../constants/apis_list';

@Injectable({
  providedIn: 'root',
})
export class JobsRepository {
  constructor(
    private httpService: HttpService
  ) {}

  getJobDetail(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', JOB_DETAIL_BASE_URL.replace('{id}', id));
  }

}
