<app-loader *ngIf="isLoading"></app-loader>
  <form [formGroup]="formGroup" novalidate>
    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <div
            class="d-flex gap-3 align-items-center mb-4 flex-column flex-wrap align-content-start w-50 float-left">
            <label htmlFor="image" class="required">
              {{ 'COMPONENTS.ARTICLES.ARTICLE_IMAGE' | translate }}
              <button
                type="button"
                class="btn-transparent ms-2 tooltip-btn"
                tooltipClass="tooltip-info-details"
                placement="right"
                [ngbTooltip]="logoTipContent">
                <i class="middle icon-info-circle-outline f-18"></i>
              </button>
            </label>
            <div class="wd-100">
              <img
                [src]="ArticleCoverImage"
                class="w-100 user-img"
                alt="ArticleCover" />
            </div>
            <input
              [attr.disabled]="pageMode === 'view' ? true : null"
              [class.disabled]="pageMode === 'view'"
              formControlName="image"
              name="image"
              type="file"
              id="image"
              hidden
              (change)="onPictureUpload($event)"
              accept="image/png, image/jpg, image/jpeg" />

            <label
              for="image"
              onKeyPress="onPictureUpload($event)"
              [class.disabled]="pageMode === 'view'"
              class="btn btn-outline btn-outline-primary btn-140">
              {{ 'COMPONENTS.ITEMS.UPLOAD_PHOTO' | translate }}
            </label>

            <div class="upload-image">
              <ng-container
              [ngTemplateOutlet]="reqErrRef"
              [ngTemplateOutletContext]="{
                control: formGroup.controls['image']
              }"></ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-4 align-items-center d-flex justify-content-between flex-row-reverse">
          <div class="d-flex flex-row-reverse">
            @if (isEditMode() && isLoading === false) {
              <status
                [data]="baseModel"
                [element]="baseModel"
                [options]="constantList.STATUS_ARTICLES"
                [column]="column"
                (updatedStatus)="updateStatus($event.status)"></status>
            }@else {
              <div class="d-inline-block" ngbDropdown>
                <button
                  class="dropdown-toggle btn-dropdown btn-200 btn-outline btn-outline-primary ms-2 mb-0 fixed-width-dropdown"
                  id="dropdownStatus"
                  ngbDropdownToggle
                >
                  <ng-container *ngIf="formGroup.controls['isPublished'].value; else unpublishedStatus">
                    <i class="icon middle me-2 icon-tick-circle"></i>
                  </ng-container>
                  <ng-template #unpublishedStatus>
                    <i class="icon middle me-2 icon-close-circle"></i>
                  </ng-template>
                  {{ getStatusText(formGroup.controls['isPublished'].value) | translate }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownStatus" class="dropdown-menu">
                  <button
                    class="dropdown-item"
                    *ngFor="let statusOption of statusOptions"
                    (click)="onStatusChange(statusOption.isPublished)"
                  >
                    <i class="icon me-1" [ngClass]="statusOption.icon"></i>
                    {{ statusOption.label | translate }}
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="col-md-12">
        <div class="form-field mb-4">
          <label htmlFor="title" class="required">
            {{ 'COMPONENTS.ARTICLES.ARTICLE_TITLE' | translate }}
          </label>
          <input
            [class.disabled]="pageMode === 'view'"
            id="title"
            appNoConsecutiveSpaces
            name="title"
            class="form-control"
            [ngClass]="{
              'is-invalid': formGroup.controls['title'].invalid && formGroup.controls['title'].touched
            }"
            formControlName="title"
            type="text"
            placeholder="{{ 'COMPONENTS.ARTICLES.ARTICLE_TITLE' | translate }}" />
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['title'],
              name: 'title'
            }"></ng-container>
        </div>
      </div>

      <div class="col-md-12">
        <div class="form-field mb-4">
          <label htmlFor="description" class="required">
            {{ 'COMPONENTS.ARTICLES.ARTICLE_DESCRIPTION' | translate }}
          </label>
          <input
            [class.disabled]="pageMode === 'view'"
            id="description"
            appNoConsecutiveSpaces
            name="description"
            class="form-control"
            [ngClass]="{
              'is-invalid': formGroup.controls['description'].invalid && formGroup.controls['description'].touched
            }"
            formControlName="description"
            type="text"
            placeholder="{{ 'COMPONENTS.ARTICLES.ARTICLE_DESCRIPTION' | translate }}" />
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['description'],
              name: 'description'
            }"></ng-container>
        </div>
      </div>
      <div class="form-field mb-4">
        <label htmlFor="title">
          {{ 'COMPONENTS.ARTICLES.AUTHOR_NAME' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          id="author"
          appNoConsecutiveSpaces
          name="author"
          class="form-control"
          [ngClass]="{
            'is-invalid': formGroup.controls['author'].invalid && formGroup.controls['author'].touched
          }"
          formControlName="author"
          type="text"
          placeholder="{{ 'COMPONENTS.ARTICLES.AUTHOR_NAME' | translate }}" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: formGroup.controls['author'],
            name: 'author'
          }"></ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="Schedule">
            {{ 'COMPONENTS.ARTICLES.PUBLISHED_ON' | translate }}
          </label>
          @if(pageMode === 'edit' || pageMode === 'add'){
            <div class="form-field mb-4">
              <input
                [class.disabled]="
                  pageMode === 'view' || (pageMode === 'edit' && hasSpecificRole === false) || pageMode === 'add'
                "
                class="form-control"
                name="publishedOn"
                id="publishedOn"
                [value]="(publishedOn | date: 'MMM dd, yyyy \'at\' hh:mm a' ) "
                type="text"
                readonly
                onfocus="this.removeAttribute('readonly');"
                placeholder=""/>
              <ng-container
                [ngTemplateOutlet]="reqErrRef"
                [ngTemplateOutletContext]="{ control: formGroup.controls['id'] }"></ng-container>
          </div>
        }
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="Schedule">
            {{ 'COMPONENTS.ARTICLES.SCHEDULE_PUBLISHING' | translate }}
          </label>

          <div class="form-group">
           <div class="d-flex gap-2">
             <input
              type="checkbox"
              class="checkbox"
              name="Schedule"
              (change)="toggleDateTimePicker($event)"
              [checked]="isDatePickerEnabled"
            />

            <input
              type="datetime-local"
              id="dateTimePicker"
              class="form-control date-time-picker"
              formControlName="isScheduled"
              onclick="this.showPicker()"
              [disabled]="!isDatePickerEnabled"
              [min]="minDateTime"
            />
           </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="tags" class="required">
            {{ 'COMPONENTS.ARTICLES.ARTICLE_TAGS' | translate }}
          </label>
          <div
            [ngClass]="{
              'is-invalid': formGroup.controls['tags'].invalid && formGroup.controls['tags'].touched
            }"
          >
              <ng-multiselect-dropdown
                class="bg-white w-100 cus-select clear-disable"
                [settings]="multiDropdownSimilarArticlesSettings"
                [data]="articleTagsArray"
                [(ngModel)]="formGroup.value['tags']"
                name="tags"
                bindValue="id"
                formControlName="tags"
                placeholder="{{ 'COMPONENTS.ARTICLES.SELECT_TAGS' | translate }}">
            `</ng-multiselect-dropdown>
          </div>
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['tags']
            }"></ng-container>
        </div>
      </div>

      <div *ngIf="pageMode === 'edit'" class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="id" class="required">
            {{ 'COMPONENTS.ARTICLES.ARTICLE_ID' | translate }}
          </label>
          <input
            id="id"
            [class.disabled]="
              pageMode === 'view' || (pageMode === 'edit' && hasSpecificRole === false)
            "
            name="id"
            class="form-control"
            formControlName="id"
            type="text"
            readonly
            onfocus="this.removeAttribute('readonly');"
            placeholder="" />
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{ control: formGroup.controls['id'] }"></ng-container>
        </div>
      </div>

      <div class="form-field mb-4">
        <label htmlFor="content" class="required">
          {{ 'COMPONENTS.ARTICLES.ARTICLE_CONTENT' | translate }}
        </label>

        <div [ngClass]="{ 'is-invalid': formGroup.controls['content'].invalid && formGroup.controls['content'].touched }">
          <quill-editor
            formControlName="content"
            [style]="{ height: '200px', width: '100%' }"
            [modules]="editorModules"
          ></quill-editor>
        </div>
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: formGroup.controls['content'],
            name: 'content'
          }"
        ></ng-container>
      </div>

    </div>

    <div *ngIf="pageMode === 'edit'" class="d-flex justify-content-end gap-2">
      <button
        (click)="setArticle()"
        type="button"
        class="btn btn-outline btn-outline-primary btn-140">
        {{ 'GENERAL.BUTTONS.RESET' | translate }}
      </button>
      <button
        type="button"
        appDebounceClick
        (debounceClick)="onSubmit('update')"
        class="btn btn-primary btn-140"
        [disabled]="isSaving"
        >
        {{ 'GENERAL.BUTTONS.UPDATE' | translate }}
      </button>
    </div>
    <div *ngIf="pageMode === 'add'" class="d-flex justify-content-end gap-2">
      <button
        (click)="setArticle('cancel')"
        type="button"
        class="btn btn-outline btn-outline-primary btn-140">
        {{ 'GENERAL.BUTTONS.CANCEL' | translate }}
      </button>
      <button
        type="button"
        appDebounceClick
        (debounceClick)="onSubmit('add')"
        class="btn btn-primary btn-140"
        [disabled]="isSaving"
        >
        {{ 'GENERAL.BUTTONS.SAVE' | translate }}
      </button>
    </div>
  </form>



<ng-template #logoTipContent>
  <ul class="mb-0 ps-3">
    <li class="tx-12">Size: 2MB</li>
    <li class="tx-12">Dimensions: 1200x900</li>
  </ul>
</ng-template>

<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
    } @else if (name === 'title' && control?.hasError('maxlength')) {
      <small class="text-danger">
        {{ 'COMMON.VALIDATION.ARTICLE_TITLE_LIMIT_EXCEED' | translate }}
      </small>
    }@else if (name === 'description' && control?.hasError('maxlength')) {
      <small class="text-danger ">
        {{ 'COMMON.VALIDATION.ARTICLE_DESCRIPTION_LIMIT_EXCEED' | translate }}
      </small>
    }
    @else if (name === 'content' && control?.hasError('maxlength')) {
      <small class="text-danger pt-4">
        {{ 'COMMON.VALIDATION.ARTICLE_CONTENT_LIMIT_EXCEED' | translate }}
      </small>
    }@else if (name === 'author' && control?.hasError('maxlength')) {
      <small class="text-danger pt-4">
        {{ 'COMMON.VALIDATION.AUTHOR_NAME_LIMIT_EXCEED' | translate }}
      </small>
    }
  }
</ng-template>
