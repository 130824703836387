<app-loader *ngIf="isLoading"></app-loader>

<div class="job-detail">
  <!-- Section 1: General Info -->
  <div class="col-lg-12">
    <div class="card mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <p class="mb-0">{{ 'COMPONENTS.TASK_AND_PROJECTS.STATUS' | translate }}</p>
          </div>
          <div class="col-sm-3">
            <p class="text-muted mb-0">{{(job?.proposal?.jobStatus | titlecase) || 'Pending' }}</p>
          </div>
          <div class="col-sm-3">
            <p class="mb-0">{{ 'COMPONENTS.TASK_AND_PROJECTS.JOB_TITLE' | translate }}</p>
          </div>
          <div class="col-sm-3">
            <p class="text-muted mb-0">{{job?.title || 'NA' }}</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-3">
            <p class="mb-0">{{ 'COMPONENTS.TASK_AND_PROJECTS.DESCRIPTION' | translate }}</p>
          </div>
          <div class="description col-sm-3">
            <p class="text-muted mb-0">{{job?.description || 'NA' }}</p>
          </div>
          <div class="col-sm-3">
            <p class="mb-0">{{ 'COMPONENTS.TASK_AND_PROJECTS.LOCATION' | translate }}</p>
          </div>
          <div class="col-sm-3">
            <p class="text-muted mb-0">{{job?.location || 'NA' }}</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-3">
            <p class="mb-0">{{ 'COMPONENTS.TASK_AND_PROJECTS.BUDGET_RANGE' | translate }}</p>
          </div>
          <div class="col-sm-3">
            <p class="text-muted mb-0">{{ job?.minBudget && job?.maxBudget ? 'AED ' + job?.minBudget + ' - AED ' + job?.maxBudget : 'NA' }}</p>
          </div>
          <div class="col-sm-3">
            <p class="mb-0">{{ 'COMPONENTS.TASK_AND_PROJECTS.TYPE' | translate }}</p>
          </div>
          <div class="col-sm-3">
            <p class="text-muted mb-0">{{(job?.spaceType | titlecase) || 'NA' }}</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-3">
            <p class="mb-0">{{ 'COMPONENTS.TASK_AND_PROJECTS.AREA' | translate }}</p>
          </div>
          <div class="col-sm-3">
            <p class="text-muted mb-0">{{formattedArea || 'NA' }}</p>
          </div>
          <div class="col-sm-3">
            <p class="mb-0">{{ 'COMPONENTS.TASK_AND_PROJECTS.STYLE' | translate }}</p>
          </div>
          <div class="col-sm-3">
            <p class="text-muted mb-0">{{job?.spaceStyle?.name || 'NA' }}</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-3">
            <p class="mb-0">{{ 'COMPONENTS.TASK_AND_PROJECTS.NO_OF_ROOMS' | translate }}</p>
          </div>
          <div class="col-sm-3">
            <p class="text-muted mb-0">{{formattedRooms || 'NA' }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-2" [viewPermission]="[this.permissionsList.UPDATE_USERS]">
      <button (click)="onCancel()" type="button" class="btn btn-outline btn-outline-primary btn-140">
        {{ 'GENERAL.BUTTONS.CLOSE' | translate }}
      </button>
      <button [disabled]="!job?.contract?.id" (click)="openContractModal()" type="button" class="btn btn-primary btn-140">
        {{ 'GENERAL.BUTTONS.CONTRACT' | translate }}
      </button>
    </div>

  </div>


<!-- Contract Details Modal -->
<div *ngIf="isContractModalOpen" class="modal-overlay">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="closeContractModal()" class="close-btn">&times;</button>
    </div>
    <div class="modal-body">
      <div class="form-row">
        <div class="form-label">
          <label>{{ 'ID' | translate }}:</label>
        </div>
        <div class="form-value">
          <p class="text-muted">{{ job?.contract?.id  ?? 'NA' }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-label">
          <label>{{ 'Assigned ID Full Name' | translate }}:</label>
        </div>
        <div class="form-value">
          <p class="text-muted">{{ job?.proposal?.professional?.firstName}} {{job?.proposal?.professional?.lastName }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-label">
          <label>{{ 'Down Payment (AED)' | translate }}:</label>
        </div>
        <div class="form-value">
          <p class="text-muted">{{ job?.contract?.mileStones?.[0]?.payment  ?? 'NA' }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-label">
          <label>{{ 'Final Payment (AED)' | translate }}:</label>
        </div>
        <div class="form-value">
          <p class="text-muted">{{ job?.contract?.mileStones?.[1]?.payment ?? 'NA' }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-label">
          <label>{{ 'Total Budget (AED)' | translate }}:</label>
        </div>
        <div class="form-value">
          <p class="text-muted">{{getTotalBudget() ?? 'NA' }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-label">
          <label>{{ 'Start Date' | translate }}:</label>
        </div>
        <div class="form-value">
          <p class="text-muted">{{ (job?.contract?.startDate | date: 'dd/MM/yyyy') ?? 'NA' }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-label">
          <label>{{ 'End Date' | translate }}:</label>
        </div>
        <div class="form-value">
          <p class="text-muted">{{ (job?.contract?.endDate | date: 'dd/MM/yyyy') ?? 'NA' }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
