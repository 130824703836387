<app-table
  [paramFilters]="paramFilters"
  [endPointConfiguration]="endPointConfiguration"
  [displayedColumnsViewArray]="displayedColumnsViewArray"
  [enableTableAction]="false"
  [canUpdateStatus]="true"
  notFoundMessage="MSGS.GENERAL.CLICK_ON_NEW_TO_CREATE_YOUR_FIRST_ENTRY"
  (tableAction)="tableAction($event)"
  (elementClick)="onElementClick($event)">
</app-table>

