import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from '@src/app/core/base';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { DebounceClickDirectiveModule } from '@src/app/core/shared/directives/debounceClick/debounceClick.directive.module';
import { NoConsecutiveSpacesDirectiveModule } from '@src/app/core/shared/directives/noConsecutiveSpaces/noConsecutiveSpaces.directive.module';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { SharedModule } from '@src/app/core/shared/shared.module';
import { CountryISO, NgxIntlTelInputModule } from 'ngx-intl-tel-input-saz';
import { OnlyIntegerDirectiveModule } from '@src/app/core/shared/directives';
import { LoaderModule } from '@src/app/core/shared/components/loader/loader.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import {  STATUS_ARTICLES } from '@src/app/core/constants';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { contentLimitValidator } from '@src/app/core/utils/content-limit-validator.util';
import { FileUploadService } from '@src/app/core/services/file-upload.service';
import { catchError, finalize, of, tap } from 'rxjs';
import { UuidService } from '@src/app/core/services/uuid.service';
import { ArticlesRepository } from '../../core/repositories/articles.repository';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { QuillEditorComponent } from 'ngx-quill';
import { ARTICLE_STATUS_UPDATE } from '../../core/constants/apis_list';


export enum Role {
  Admin = 'admin',
  'Content Creator' = 'content_creator',
  Operator = 'operator',
}
@Component({
  selector: 'app-article-detail',
  standalone: true,
  templateUrl: './article-detail.component.html',
  styleUrl: './article-detail.component.scss',
  imports: [
    ...COMMON_MODULES,
    NgbNavModule,
    NgSelectModule,
    ConfirmDialogModule,
    NgbDropdownModule,
    NgbTooltipModule,
    DebounceClickDirectiveModule,
    ViewPermissionDirectiveModule,
    NoConsecutiveSpacesDirectiveModule,
    SharedModule,
    AppStatus,
    NgxIntlTelInputModule,
    NgbDatepickerModule,
    OnlyIntegerDirectiveModule,
    LoaderModule,
    NgMultiSelectDropDownModule,
    QuillModule,
  ]
})
export class ArticleDetailComponent extends BaseComponent implements OnInit, AfterViewInit {


  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public dialogMessage: any = '';
  public dialogConfirmText: any = '';
  public confirmEventAction: string = '';
  public disableRoles: boolean = false;
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public isOpenAlternative: boolean = false;
  public isOpenSimilar: boolean = false;
  public userType: any = 'admin_user';
  public hasSpecificRole: boolean = false;
  public CountryISO = CountryISO;
  public ArticleCoverImage: any = 'assets/images/thumbnail.svg';
  public coverImageFile: any = null;
  public rolesList: any = Role;
  public similarArticlesArray: Array<any> = [];
  public multiDropdownSettings = {};
  public multiDropdownSimilarArticlesSettings = {};
  public  isDatePickerEnabled = false;
  public minDateTime: string = '';
  public articleTagsArray: Array<any> = [];
  public publishedOn: any
  public initialFormValues: any;
  isCoverImageUploaded = true;
  article_status= STATUS_ARTICLES

  // Toolbar options for Quill Editor
  editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['link', 'image'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  statusOptions: any[] = [
    {
      label: 'GENERAL.BUTTONS.PUBLISHED',
      value: 'Published',
      isPublished: true,
      icon: 'icon-tick-circle',
    },
    {
      label: 'GENERAL.BUTTONS.UNPUBLISHED',
      value: 'Unpublished',
      isPublished: false,
      icon: 'icon-close-circle',
    },
  ];

  @ViewChild(QuillEditorComponent) quillEditorRef!: QuillEditorComponent;

  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(null),
    title: new FormControl(null, [Validators.required,Validators.maxLength(90)]),
    description: new FormControl(null, [Validators.required,Validators.maxLength(150)]),
    author: new FormControl(null, [Validators.maxLength(80)]),
    image: new FormControl(''),
    tags: new FormControl(null, [Validators.required]),
    content: new FormControl(null, [Validators.required, contentLimitValidator(10000)]),
    isScheduled: new FormControl({ value: '', disabled: true }),
    isPublished : new FormControl(true)
  });


  public column: any = {
    key: 'status',
    value: 'COMPONENTS.ARTICLES.STATUS',
    headingClass: 'ps-4',
    type: 'status',
    keyValue: 'isPublished',
    sortable: false,
    options: [
      {
        label: 'GENERAL.BUTTONS.PUBLISHED',
        value: 'Published',
        icon: 'tick-circle',
      },
      {
        label: 'GENERAL.BUTTONS.UNPUBLISHED',
        value: 'Unpublished',
        icon: 'close-circle',
      },
    ],
    endpoint: ARTICLE_STATUS_UPDATE,
    success: this.translate.instant('MSGS.ARTICLES.UPDATE_ARTICLE_STATUS'),
    successSecond: this.translate.instant('MSGS.ARTICLES.UPDATE_ARTICLE_STATUS_DEACTIVATED'),
    alert: this.translate.instant('MSGS.ARTICLES.ARE_YOU_SURE_UNPUBLISH'),
    alertSecond: this.translate.instant('MSGS.ARTICLES.ARE_YOU_SURE_PUBLISH'),
    error: this.translate.instant('MSGS.ARTICLES.UNABLE_TO_UPDATE_Article_STATUS'),
  };

  constructor(
      injector: Injector,
      private readonly pageSettingsService: PageSettingsService, // Service for managing page settings
      private readonly fileUploadService: FileUploadService,
      private readonly uuidService: UuidService,
      private readonly articlesRepository: ArticlesRepository,
    ) {
      super(injector);
      // If the page is not in edit mode, set the pageMode to 'add'
      if (this.isEditMode() == null) {
        this.pageMode = 'add';
      };

      this.fetchArticleTags();

      const newSettings = {
        link1: {
          title: 'BREADCRUMBS.BLOGS.TITLE', // Breadcrumb title
          name: 'MENU.ARTICLES', // Breadcrumb name
          link: this.routeList.ARTICLES, // Link for the breadcrumb
        },
        link2: {
          title:
            this.pageMode === 'add'
              ? 'BREADCRUMBS.BLOGS.ADD_ARTICLE'
              : 'BREADCRUMBS.BLOGS.EDIT_ARTICLE',
        },
      };
      // Using the page settings service to update the page settings
      this.pageSettingsService.updatePageSettings(newSettings);
  }

  removeAsyncValidationsAndError(controlName: string){
    // Reset dirty state
    this.formGroup.get(controlName)?.markAsPristine();
    // Clear pending state (if any async validation is applied)
    this.formGroup.get(controlName)?.clearAsyncValidators();
      // Optionally, mark the control as untouched as well
    this.formGroup.get(controlName)?.markAsUntouched();
  }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params['id']) {
        // Fetch the article data if we are in edit mode
        this.loadArticleDetails(params['id']);
      }
    });

    setTimeout(() => {
      this.removeAsyncValidationsAndError('tags');
    }, 0);

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['type']) {
        this.userType = params['type'];
        if (this.userType == 'app_user' && this.pageMode == 'edit') {
          this.disableRoles = true;
        } else this.disableRoles = false;
      }
    });

    this.multiDropdownSimilarArticlesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect all',
      allowSearchFilter: true,
    };
  }

  ngAfterViewInit() {
    this.loadContentIntoQuillEditor();
  }

  loadContentIntoQuillEditor() {
    if (this.quillEditorRef && this.quillEditorRef.quillEditor) {
      const quillEditor = this.quillEditorRef.quillEditor;
      quillEditor.root.innerHTML = this.formGroup.get('content')?.value || '';  // Set content in Quill editor
    }

    this.multiDropdownSimilarArticlesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect all',
      allowSearchFilter: true,
    };
  }


  protected setArticle(id?: string) {
    this.formGroup.updateValueAndValidity({ emitEvent: false });
    if (id === 'cancel') {
      this.formGroup.patchValue({}, { emitEvent: false });
      this.helperService.goToRoute(this.routeList.ARTICLES);
    } else {
      this.formGroup.patchValue({
        id: this.initialFormValues.id,
        title: this.initialFormValues.title,
        description: this.initialFormValues.description,
        author: this.initialFormValues.author,
        content: this.initialFormValues.content,
        tags: this.initialFormValues.tags,
        published: this.initialFormValues.published,
      }, { emitEvent: false });
    }
  }


   /**
     * Updates the status of the segment.
     *
     * @param {string} status - The new status of the segment.
     * @return {void} This function does not return anything.
     */

    public updateStatus(status: string): void {
      this.httpService
        .requestEntity('PATCH', ARTICLE_STATUS_UPDATE.replace('{id}', this.baseModel.id), {
          status: status,
        })
        .pipe(this.destroy$())
        .subscribe({
          next: (res: any) => {
            if (res.status === this.constantList.SUCCESS_STATUS || res.status === this.constantList.SUCCESS_STATUS_CODE) {
              this.httpService.showMessage(
                this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
                'success'
                );

                this.baseModel.isPublished = res.body?.isPublished;

            }
          },
          error: (error) => {
            this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.STATUS_UPDATE_FAILED'), 'error');
          }
        });
    }


  protected updateMinDateTime(scheduledAt?: Date): void {
    const now = scheduledAt || new Date(); // Default to current time if no scheduled time is provided
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    this.minDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  }


  protected toggleDateTimePicker(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.isDatePickerEnabled = checkbox.checked;

    if (checkbox.checked) {
      this.formGroup.controls['isScheduled'].enable();
    } else {
      this.formGroup.controls['isScheduled'].disable();
      this.formGroup.controls['isScheduled'].reset();
    }

    // Update the minimum date/time whenever the date picker is enabled
    this.updateMinDateTime();
  }

  fetchArticleTags(): void {
    this.isLoading = true;

    this.articlesRepository
      .search({ 'filter.isActivated': true, limit: 100 })
      .pipe(
        tap(() => this.isLoading = false),
        catchError(error => of(error))
      )
      .subscribe(response => {
        const { body } = response as ApiResponseInterface<any>;
        this.articleTagsArray = body?.data || [];
      });
  }


  private loadArticleDetails(articleId: string): void {
    this.isLoading = true;

    this.articlesRepository.getById(articleId).pipe(
      tap((response: ApiResponseInterface<any>) => {
        const article: any = response.body;
        if (article) {
          this.initialFormValues = { ...article };
          this.baseModel = article;

          // Populate the form with article data
          this.formGroup.patchValue({
            id: article.id,
            title: article.title,
            description: article.description,
            author: article.author,
            content: article.content,
            tags: article.tags, // Convert tag IDs to objects
            isScheduled: article.scheduledAt ? new Date(article.scheduledAt).toISOString().slice(0, 16) : null, // Format scheduledAt for datetime-local input
          });

          // Update the Quill editor content
          this.loadContentIntoQuillEditor();

          this.publishedOn = article.publishedAt;

          this.ArticleCoverImage = article.image || 'assets/images/thumbnail.svg';

        }
      }),
      catchError((error) => {
        this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.LOAD_FAILED'), 'error');
        return of(null);
      }),
      finalize(() => (this.isLoading = false))
    ).subscribe();
  }


  protected onPictureUpload(event: any) {
    // Validate the uploaded image
    if (!event.target.files.length) return;
  
    const file = event.target.files[0];
  
    // Sanitize the file name (remove special characters, replace spaces with "_")
    const sanitizedFileName = file.name
      .replace(/[^a-zA-Z0-9.-]/g, '') // Remove special characters
      .replace(/\s+/g, '_'); // Replace spaces with "_"
  
    // Set the selected image details with the sanitized file name
    this.coverImageFile = {
      file: file,
      fileName: sanitizedFileName, // ✅ Use sanitized file name
    };
  
    //  Create an instance of FileReader to show image preview
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result; // Use FileReader result as the image source
  
      img.onload = () => {
        // Set the image source for preview
        this.ArticleCoverImage = reader.result;
        this.isCoverImageUploaded = false;
      };
    };
  
    // Read the image as a data URL to show the preview
    reader.readAsDataURL(new Blob([file], { type: file.type }));
  }



  private submitArticle(type: string, payload: any): void {

    const articleId = this.baseModel?.id || this.route.snapshot.paramMap.get('id');

    const submission$ =
      type === 'add'
        ? this.articlesRepository.create(payload)
        : this.articlesRepository.update(articleId, payload); // Use `updateArticleDetail` for edit mode

    submission$
      .pipe(
        tap(() => {
          this.httpService.showMessage(
            this.translate.instant(type === 'add' ? 'MSGS.ARTICLES.SAVE_SUCCESS' : 'MSGS.ARTICLES.UPDATE_SUCCESS'),
            'success'
          );
          this.helperService.goToRoute(this.routeList.ARTICLES);
        }),
        catchError((error) => {
          console.error('Error during submission:', error);
          return of(null);
        }),
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe();
  }


  protected onSubmit(type: string): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    // Disable the save button after it's clicked
    this.isSaving = true;

    // Get the content from Quill editor
    const quillEditor = document.querySelector('.ql-editor');
    const contentText = quillEditor ? quillEditor.textContent?.trim() : '';

    const realArticleId = this.baseModel?.id; // Get the real article ID for updates
    const tempUUID = this.baseModel?.tempUUID || this.uuidService.generateUuid(); // Generate UUID if not available

    // Prepare the payload with other form fields
    const payload: any = {
      title: this.formGroup.value.title,
      description: this.formGroup.value.description,
      ...(this.formGroup.value.author ? { author: this.formGroup.value.author } : {}), // include author only if it's present
      content: contentText,
      ...(type !== 'add' ? {} : { isPublished: this.formGroup.value.isPublished }), // Add isPublished only if in add mode
      tags: this.formGroup.value.tags.map((tag: any) => tag.id),
      scheduledAt: this.formGroup.value.isScheduled,
      isScheduled: !!this.formGroup.value.isScheduled,
      ...(type !== 'add' ? {} : { tempUUID }), // Include tempUUID only when adding a new article
    };

    // Handle image upload if the image is provided
    if (this.coverImageFile?.file) {
      this.isSaving = true;

      // Use uploadFilesToResource to upload the image
      const filesToUpload: File[] = [this.coverImageFile.file];

      // For update, use the real article ID (not the UUID)
      const idToUse = type === 'update' ? realArticleId : tempUUID; // Use real article ID when updating

      this.fileUploadService.uploadFilesToResource('articles', idToUse, [this.coverImageFile.fileName], filesToUpload, ['articles'])
        .pipe(
          tap((uploadedFiles: any) => {
            if (uploadedFiles.length > 0) {
              // After the image upload, use the returned file URL (or ID) from the backend
              const uploadedImageUrl = uploadedFiles[0].fileName; // If `url` is returned by the backend, use it
              payload.image = uploadedImageUrl; // Add the real image URL to the payload
            }
          }),
          catchError((error) => {
            console.error('Error during image upload:', error);
            this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.UPLOAD_FAILED'), 'error');
            return of(null);
          }),
          finalize(() => {
            // After the image upload completes, submit the article
            this.submitArticle(type, payload);
          })
        )
        .subscribe();
    } else {
      // If no image is uploaded, don't include the image in the payload
      this.submitArticle(type, payload);
    }
  }

  getStatusText(isPublished: boolean): string {
    return isPublished ? 'COMMON.TEXT.PUBLISHED' : 'COMMON.TEXT.UNPUBLISHED';
  }

  onStatusChange(isPublished: boolean): void {
    console.log('Status updated:', isPublished ? 'Published' : 'Unpublished');
    this.formGroup.controls['isPublished'].setValue(isPublished); 
  }

}
