export var AlgorithmId;
(function (AlgorithmId) {
  AlgorithmId["MD5"] = "md5";
  AlgorithmId["CRC32"] = "crc32";
  AlgorithmId["CRC32C"] = "crc32c";
  AlgorithmId["SHA1"] = "sha1";
  AlgorithmId["SHA256"] = "sha256";
})(AlgorithmId || (AlgorithmId = {}));
export const getChecksumConfiguration = runtimeConfig => {
  const checksumAlgorithms = [];
  if (runtimeConfig.sha256 !== undefined) {
    checksumAlgorithms.push({
      algorithmId: () => AlgorithmId.SHA256,
      checksumConstructor: () => runtimeConfig.sha256
    });
  }
  if (runtimeConfig.md5 != undefined) {
    checksumAlgorithms.push({
      algorithmId: () => AlgorithmId.MD5,
      checksumConstructor: () => runtimeConfig.md5
    });
  }
  return {
    addChecksumAlgorithm(algo) {
      checksumAlgorithms.push(algo);
    },
    checksumAlgorithms() {
      return checksumAlgorithms;
    }
  };
};
export const resolveChecksumRuntimeConfig = clientConfig => {
  const runtimeConfig = {};
  clientConfig.checksumAlgorithms().forEach(checksumAlgorithm => {
    runtimeConfig[checksumAlgorithm.algorithmId()] = checksumAlgorithm.checksumConstructor();
  });
  return runtimeConfig;
};