import { AfterViewInit, Component, Injector, signal, ViewChild } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiEndPointConfiguration } from '@src/app/app-types';
import { BaseComponent } from '@src/app/core/base';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { AllowedTableActionsData, ROW_NOT_CLICKABLE } from '@src/app/core/constants/table-actions.constant';
import { AllowedTableActions } from '@src/app/core/interfaces/table-actions.interface';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { TableComponent } from '@src/app/core/shared/components/table/table.component';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDialogModule } from "../../../../core/shared/components/confirm-dialog/confirm-dialog.module";
import { TableModule } from "../../../../core/shared/components/table/table.module";
import { JOB_BASE_URL } from '../../core/constants/apis_list';
import { JobsRepository } from '../../core/repositories/jobs.repository';

@Component({
  selector: 'app-jobs-listing',
  standalone: true,
  imports: [...COMMON_MODULES,TableModule,NgbDropdownModule, ConfirmDialogModule],
  templateUrl: './jobs-listing.component.html',
  styleUrl: './jobs-listing.component.scss',
})
export class JobsListingComponent extends BaseComponent implements AfterViewInit  {
  @ViewChild(TableComponent, { static: false }) tableCompRef!: TableComponent;
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;

  // https://sid-stg-be.ideatolife.me/articles?search=&filter.createdAt=$btw:2025-02-10,2025-02-21T18:59:00.000Z&page=1&limit=20&sortBy=createdAt:DESC
  // https://sid-stg-be.ideatolife.me/jobs?search=&filter.createdAt=$btw:2025-02-06,2025-02-10T18:59:00.000Z&page=1&limit=20&sortBy=createdAt:DESC
  public displayedColumnsViewArray: any[] = [];
  public allowedTableActions: AllowedTableActions = AllowedTableActionsData;
  public paramFilters: any[] = [
    {
      key: 'jobStatus',
      type: 'option',
      placeholder: 'COMMON.TEXT.STATUS',
      options: this.constantList.JOBS_STATUS
    },
    {
      key: 'filter.spaceType',
      type: 'option',
      placeholder: 'COMMON.TEXT.TYPE',
      options: this.constantList.JOBS_TYPES
    },
    {
      type: 'date-from',
      key: 'filter.dateFrom',
      placeholder: 'COMMON.TEXT.FROM',
      filterKey: 'filter.proposals.contract.startDate',
      name: 'filter.dateFrom',
      maxTo: 'filter.dateTo',
    },
    {
      type: 'date-to',
      key: 'filter.dateTo',
      placeholder: 'COMMON.TEXT.TO',
      filterKey: 'filter.proposals.contract.startDate',
      name: 'filter.dateTo',
      minFrom: 'filter.dateFrom',
    },
  ];

  public endPointConfiguration: ApiEndPointConfiguration = {
    method: 'GET',
    url: JOB_BASE_URL, // Placeholder API endpoint for articles
  };

  private selectedRows = signal([]);
  public articles$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public confirmEventAction: 'delete' | null = null;

  constructor(
    private injector: Injector, // Angular's injector service
    private readonly pageSettingsService: PageSettingsService, // Service for managing page settings
    private readonly jobsRepository: JobsRepository,
  ) {
     // Calling the parent constructor (BaseComponent) and passing the injector
     super(injector);

      // Initializing allowed table actions
      this.allowedTableActions.pending = true; // Setting 'pending' action as allowed
      this.allowedTableActions.draft = false; // Setting 'draft' action as not allowed

    // Update breadcrumb settings for the page
    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.TASK_AND_PROJECTS.TITLE', // Breadcrumb title
        name: 'MENU.JOBS', // Breadcrumb name
        link: this.routeList.JOBS, // Link for the breadcrumb
      },
    };
    this.pageSettingsService.updatePageSettings(newSettings);

  }

  ngAfterViewInit(): void {
    // Configure table columns
    setTimeout(() => {
      this.displayedColumnsViewArray = [
        {
          key: 'title',
          value: 'COMPONENTS.TASK_AND_PROJECTS.JOB_TITLE',
          type: 'text',
          sortable: false,
          className: 'text-truncate'
        },
        {
          key: 'user',
          value: 'COMPONENTS.TASK_AND_PROJECTS.JOB_OWNER',
          type: 'text',
          sortable: false,
          className: 'text-truncate',
          callback: (user: any) => `${user.firstName} ${user.lastName}`,
        },
        {
          key: 'spaceType',
          value: 'COMPONENTS.TASK_AND_PROJECTS.JOB_TYPE',
          type: 'text',
          sortable: false,
          className: 'w-10 text-center',
        },
        {
          key: 'proposals',
          value: 'COMPONENTS.TASK_AND_PROJECTS.ASSIGNED_ID',
          type: 'text',
          sortable: false,
          className: 'w-10 text-center text-truncate',
          callback: (proposals: any) => {
            if (proposals.length > 0) {
              return `${proposals[0].professional.firstName} ${proposals[0].professional.lastName}`
            }

            return '';
          }},
        {
          key: 'contract',
          value: 'COMPONENTS.TASK_AND_PROJECTS.DOWN_PAYMENT',
          type: 'text',
          sortable: false,
          className: 'w-10 text-center',
          callback: (contract: any) => {
            if (contract && contract.mileStones && contract.mileStones.length > 1) {
              const paymentNumber = Number(contract.mileStones[1].payment);
              if (!isNaN(paymentNumber)) {
                return `${paymentNumber.toFixed(2)}`;
              } else {
                return '';
              }
            } else {
              return '';
            }
          }
        },
        {
          key: 'contract',
          value: 'COMPONENTS.TASK_AND_PROJECTS.FINAL_PAYMENT',
          type: 'text',
          sortable: false,
          className: 'w-10 text-center',
          callback: (contract: any) => {
            if (contract && contract.mileStones && contract.mileStones.length > 0) {
              const paymentNumber = Number(contract.mileStones[0].payment);
              if (!isNaN(paymentNumber)) {
                return `${paymentNumber.toFixed(2)}`;
              } else {
                return '';
              }
            } else {
              return '';
            }
          }
        },
        {
          key: 'contract',
          value: 'COMPONENTS.TASK_AND_PROJECTS.TOTAL_BUDGET',
          type: 'text',
          sortable: false,
          className: 'w-10 text-center',
          callback: (contract: any) => {
            const total = contract?.mileStones?.reduce(
              (sum: number, milestone: { payment: string }) => sum + parseFloat(milestone?.payment || '0'),
              0
            );
            return total && total > 0 ? total.toFixed(2) : null;
          }
        },
        {
          key: 'contract.startDate',
          value: 'COMPONENTS.TASK_AND_PROJECTS.START_DATE',
          type: 'datetime',
          sortable: false,
          className: 'w-10 text-center',
        },
        {
          key: 'contract.endDate',
          value: 'COMPONENTS.TASK_AND_PROJECTS.END_DATE',
          type: 'datetime',
          sortable: false,
          className: 'w-10 text-center',
        },
        {
            key: 'proposal.jobStatus',
            value: 'COMMON.TEXT.STATUS',
            headingClass: 'ps-4',
            type: 'status',
            keyValue: 'proposal.jobStatus',
            disabled:true,
            sortable: false,
            callback: (jobStatus: any) => {
              if (!jobStatus || jobStatus === null) {
                return 'pending';
              }
              else{
                return jobStatus
              }
            },
            options: [
              {
                label: 'GENERAL.BUTTONS.ACTIVE',
                value: 'active',
                icon: 'tick-circle',
              },
              {
                label: 'GENERAL.BUTTONS.PENDING',
                value: 'pending',
                icon: 'minus-cirlce',
              },
              {
                label: 'GENERAL.BUTTONS.COMPLETED',
                value: 'completed',
                icon: 'close-circle',
              },
            ],
            // endpoint: ARTICLE_STATUS_UPDATE,
            // success: this.translate.instant('MSGS.USERS.UPDATE_USER_STATUS'),
            // successSecond: this.translate.instant('MSGS.USERS.UPDATE_USER_STATUS_DEACTIVATED'),
            // error: this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_USER_STATUS'),
          },
      ];
    }, 500);
  }

  public havePermissionToDelete = (permission: any) => {
    // Check if the permission object is an array and if it contains a role with code 'super_admin'
    return (
      Array.isArray(permission?.roles) && // Check if permission.roles is an array
      permission?.roles.some((role: any) => role?.code === 'super_admin') // Check if any role has code 'super_admin'
    );
  };


  public onElementClick(e: any) {
    // Handle different actions based on the event object

    // If 'element' property is an array with length greater than 0, set selectedRows
    if (e.element.length > 0) {
      this.selectedRows.set(e.element);
    }
    // If 'element' property is not clickable and has an ID 'from_td', navigate to user listing route
    else if (!ROW_NOT_CLICKABLE.includes(e.action) && e.id && e.id === 'from_td') {
      this.helperService.goToRoute(this.routeList.JOBS + `/${e.element.id}`);
    }
    // If 'element' property has an ID but no action, return
    else if (e.id) {
      return;
    }

    // Switch statement to handle different actions
    switch (e.action) {
      case 'delete':
        // If confirmDialCompRef exists, open delete dialog
        if (this.confirmDialCompRef) {
          this.confirmEventAction = 'delete';
          this.confirmDialCompRef.openDialog(e.element);
        }
        break;
      case 'email':
        // If action is 'email', navigate to user listing route
        this.helperService.goToRoute(this.routeList.JOBS + `/${e.element.id}`);
        break;
    }
  }


  public tableAction(event: any) { }

}
