import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from '@src/app/core/base';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { LoaderModule } from '@src/app/core/shared/components/loader/loader.module';
import { OnlyIntegerDirectiveModule } from '@src/app/core/shared/directives';
import { DebounceClickDirectiveModule } from '@src/app/core/shared/directives/debounceClick/debounceClick.directive.module';
import { NoConsecutiveSpacesDirectiveModule } from '@src/app/core/shared/directives/noConsecutiveSpaces/noConsecutiveSpaces.directive.module';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { SharedModule } from '@src/app/core/shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-saz';
import { QuillModule } from 'ngx-quill';
import { catchError, finalize, of, tap } from 'rxjs';
import { Job } from '../../core/model/job.model';
import { JobsRepository } from '../../core/repositories/jobs.repository';

export enum Role {
  Admin = 'admin',
  'Content Creator' = 'content_creator',
  Operator = 'operator',
}
@Component({
  selector: 'app-job-detail',
  standalone: true,
  templateUrl: './job-detail.component.html',
  styleUrl: './job-detail.component.scss',
  imports: [
    ...COMMON_MODULES,
    NgbNavModule,
    NgSelectModule,
    ConfirmDialogModule,
    NgbDropdownModule,
    NgbTooltipModule,
    DebounceClickDirectiveModule,
    ViewPermissionDirectiveModule,
    NoConsecutiveSpacesDirectiveModule,
    SharedModule,
    AppStatus,
    NgxIntlTelInputModule,
    NgbDatepickerModule,
    OnlyIntegerDirectiveModule,
    LoaderModule,
    NgMultiSelectDropDownModule,
    QuillModule,
  ]
})
export class JobDetailComponent extends BaseComponent implements OnInit {

  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public disableRoles: boolean = false;
  public isOpenSimilar: boolean = false;
  public isContractModalOpen: boolean = false;
  public isLoading: boolean = false;
  public job: Job | null = null;

  constructor(
      injector: Injector,
      private readonly pageSettingsService: PageSettingsService, // Service for managing page settings
      private readonly jobsRepository: JobsRepository,
    ) {
      super(injector);
      const newSettings = {
        link1: {
          title: 'BREADCRUMBS.TASK_AND_PROJECTS.TITLE', // Breadcrumb title
          name: 'MENU.JOBS', // Breadcrumb name
          link: this.routeList.JOBS, // Link for the breadcrumb
        },
        link2: {
          title:'BREADCRUMBS.TASK_AND_PROJECTS.JOB_DETAIL',
        },
      };
      // Using the page settings service to update the page settings
      this.pageSettingsService.updatePageSettings(newSettings);
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params: any) => {
      if (params['id']) {
        // Fetch the article data if we are in edit mode
        this.loadJobDetails(params['id']);
      }
    });
  }

  private loadJobDetails(jobId: string): void {
      this.isLoading = true;

      this.jobsRepository.getJobDetail(jobId).pipe(
        tap((response: ApiResponseInterface<any>) => {
          this.job = response.body;
        }),
        catchError((error) => {
          this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.LOAD_FAILED'), 'error');
          return of(null);
        }),
        finalize(() => (this.isLoading = false))
      ).subscribe();
    }


  protected onCancel(){
    this.router.navigate(['/jobs'])
  }

  // Method to open the contract modal
  openContractModal() {
    this.isContractModalOpen = true;
  }

  // Method to close the contract modal
  closeContractModal() {
    this.isContractModalOpen = false;
  }

  // Getter for formatted area
  get formattedArea() {
    const maxArea = this.baseModel.spaceMaxArea;

    if (maxArea > 2500) {
      return AreaRange.OVER_2500;
    } else if (maxArea >= 1000) {
      return AreaRange.BETWEEN_1000_2500;
    } else {
      return AreaRange.UNDER_1000;
    }
  }

  // Getter for formatted number of rooms
  get formattedRooms() {
    const maxRooms = this.job?.spaceMaxRooms || this.baseModel.spaceMaxRooms;

    if (maxRooms > 5) {
      return RoomRange.FIVE_PLUS;
    } else if (maxRooms >= 3) {
      return RoomRange.THREE_TO_FIVE;
    } else {
      return RoomRange.ONE_TO_TWO;
    }
  }

  getTotalBudget(): string | null{
    const total = this.job?.contract?.mileStones?.reduce(
      (sum: number, milestone: { payment: string }) => sum + parseFloat(milestone?.payment || '0'),
      0
    );
    return total && total > 0 ? total.toFixed(2) : null;
  }

}

export enum RoomRange {
  ONE_TO_TWO = '1 - 2 rooms',
  THREE_TO_FIVE = '3 - 5 rooms',
  FIVE_PLUS = '5+ rooms'
}

export enum AreaRange {
  UNDER_1000 = 'Under 1,000 sqft',
  BETWEEN_1000_2500 = '1,000 - 2,500 sqft',
  OVER_2500 = 'Over 2,500 sqft'
}
