<div class="user-profile">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-8">
            <a ngbNavLink>{{ 'COMPONENTS.USERS.INTERIOR_DESIGNER_PROFILE' | translate }}</a>
          </div>
          <div class="col-4 align-self-end">
            <div class="d-inline-block" ngbDropdown>
              <button
                class="dropdown-toggle btn-dropdown btn-200 btn-outline btn-outline-primary ms-2 mb-0"
                [ngClass]="baseModel.profileStatus.replace('-', '')?.replace('_', '')?.replaceAll('
  ', '')?.toLowerCase()"
                id="dropdownStatus"
                ngbDropdownToggle>
                @switch (baseModel.profileStatus) {
                  @case ('accepted') {
                    <i class="icon middle me-2 icon-tick-circle"></i>
                  }
                  @case ('rejected') {
                    <i class="icon middle me-2 icon-close-circle"></i>
                  }
                  @default {
                    <i class="icon middle me-2 icon-minus-cirlce"></i>
                  }
                }
                {{ 'COMMON.TEXT.' + baseModel.profileStatus.replace('-', '')?.replace('_', '')?.replaceAll('
  ', '')?.toUpperCase() | translate }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownStatus">
                @switch (baseModel.profileStatus) {
                  @case ('incomplete') {
                    <button ngbDropdownItem (click)="updateUserStatus('incomplete', baseModel.id)"
                    [disabled]="baseModel.profileStatus === 'incomplete'">
                      <i class="icon middle me-2 icon-minus-cirlce" [ngClass]="{'text-muted': baseModel.profileStatus === 'incomplete'}"></i>
                      {{ 'GENERAL.BUTTONS.INCOMPLETE' | translate }}
                    </button>
                  }
                  @case ('pending_review') {
                      <button ngbDropdownItem (click)="updateUserStatus('accepted', baseModel.id)"
                      [disabled]="baseModel.profileStatus === 'accepted'">
                        <i class="icon middle me-2 icon-tick-circle" [ngClass]="{'text-muted': baseModel.profileStatus === 'accepted'}"></i>
                        {{ 'GENERAL.BUTTONS.ACCEPTED' | translate }}
                      </button>
                      <button ngbDropdownItem (click)="updateUserStatus('rejected', baseModel.id)"
                      [disabled]="baseModel.profileStatus === 'rejected'">
                        <i class="icon middle me-2 icon-close-circle" [ngClass]="{'text-muted': baseModel.profileStatus === 'rejected'}"></i>
                        {{ 'GENERAL.BUTTONS.REJECTED' | translate }}
                      </button>
                  }
                  @default {
                    <button ngbDropdownItem (click)="updateUserStatus('accepted', baseModel.id)"
                    [disabled]="baseModel.profileStatus === 'accepted'">
                      <i class="icon middle me-2 icon-tick-circle" [ngClass]="{'text-muted': baseModel.profileStatus === 'accepted'}"></i>
                      {{ 'GENERAL.BUTTONS.ACCEPTED' | translate }}
                    </button>
                    <button ngbDropdownItem (click)="updateUserStatus('rejected', baseModel.id)"
                    [disabled]="baseModel.profileStatus === 'rejected'">
                      <i class="icon middle me-2 icon-close-circle" [ngClass]="{'text-muted': baseModel.profileStatus === 'rejected'}"></i>
                      {{ 'GENERAL.BUTTONS.REJECTED' | translate }}
                    </button>
                  }
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template ngbNavContent>
        <!-- Section 1: General Info -->
        <div class="col-lg-8">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">First Name</p>
                </div>
                <div class="col-sm-3">
                  <p class="text-muted mb-0">{{baseModel.firstName}}</p>
                </div>
                <div class="col-sm-3">
                  <p class="mb-0">Last Name</p>
                </div>
                <div class="col-sm-3">
                  <p class="text-muted mb-0">{{baseModel.lastName}}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Email</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{baseModel.email}}</p>
                </div>
              </div>
              <hr>
              @if(baseModel.about) {
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0">Bio</p>
                  </div>
                  <div class="col-sm-9">
                    <p class="text-muted mb-0">{{baseModel.about}}</p>
                  </div>
                </div>
                <hr>
              }
              <div class="row" *ngIf="baseModel.city">
                <div class="col-sm-3">
                  <p class="mb-0">City</p>
                </div>
                <div class="col-sm-3">
                  <p class="text-muted mb-0">{{baseModel.city}}</p>
                </div>
                <div class="col-sm-3">
                  <p class="mb-0">Time Zone</p>
                </div>
                <div class="col-sm-3">
                  <p class="text-muted mb-0">{{baseModel.timezone}}</p>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- Section 2: Extraf Information -->
    <div class="col-lg-12">
      @if(baseModel.yearsOfExperience || baseModel.skills || (baseModel.cv && baseModel.socialLink)) {
      <div class="card mb-4">
          <div class="card-body">
              <h3 class="mb-3">Extra Information</h3>

              <div class="row">
                  <div class="col-sm-3">
                      <p class="mb-0">Years of Experience</p>
                  </div>
                  <div class="col-sm-3">
                      <p class="text-muted mb-0">{{baseModel.yearsOfExperience || 'NA' }}</p>
                  </div>

                @if(baseModel.currency && baseModel.minimumCost && baseModel.maximumCost) {
                  <div class="col-sm-3">
                      <p class="mb-0">Typical Cost</p>
                  </div>
                  <div class="col-sm-3">
                      <p class="text-muted mb-0">{{baseModel.currency || 'NA' }} {{baseModel.minimumCost || 'NA' }} - {{baseModel.currency || 'NA' }} {{baseModel.maximumCost || 'NA' }}</p>
                  </div>
                }
              </div>
              <hr>
              <div class="row">
                  <div class="col-sm-1">
                      <p class="mb-0 my-2">Skills</p>
                  </div>

                  <div class="col-sm-9">
                    <div class="d-flex flex-wrap">
                      <div class="p-2 skills-tag bd-highlight btn-dropdown btn-200 btn-outline btn-outline-primary ms-2 mb-0" *ngFor="let item of baseModel.skills; let myIndex = index">{{item.skill}}</div>

                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <div class="attached-file" (click)="openURL(baseModel.cv)">
                      <img alt="" src="../../../../../../assets/images/pdf-icon.svg" />
                      <div>
                        <h4>CV</h4>
                        <div>Click to view/download</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="attached-file" (click)="openURL(baseModel.socialLink)">
                      <img alt="" src="../../../../../../assets/images/link.svg" />
                      <div>
                        <h4>Social Link</h4>
                        <div>Click to open</div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
      </div>
    }
  </div>
      <!-- Section 3: protfolio -->
    <div class="col-lg-12" *ngIf="baseModel.portfolio">
      <div class="card mb-4">
          <div class="card-body">
              <h3 class="mb-3">Portfolio</h3>
              @for (item of baseModel.portfolio; track item.key) {
                <div class="portfolio-body">
                  <div class="row">
                      <p class="mb-0">{{item.title}}</p>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="portfolio-photos">
                      <div *ngFor="let portfolioImage of item.attachments; let myIndex = index" (click)="expandImage(portfolioImage)">
                        <img class="portfolio-image" src={{portfolioImage}} alt="portfolio"/>
                      </div>
                    </div>
                  </div>
              </div>
              }
          </div>
      </div>
  </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="py-4 px-0"></div>
</div>


<app-confirm-dialog
  [confirmText]="dialogConfirmText"
  [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  [message]="dialogMessage"
  extraIcon="icon-warning-2"
  iconClass="text-danger"
  [eventType]="confirmEventAction"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>

  <reject-reason-dialog
  [confirmText]="'GENERAL.BUTTONS.SAVE' | translate"
  [closeText]="'GENERAL.BUTTONS.CANCEL' | translate"
  [title]="'MSGS.USERS.REJECTION_DIALOG_TITLE' | translate"
  [placeholder]="'MSGS.USERS.STATE_THE_REASON' | translate"
  [eventType]="confirmEventAction"
  (closeDialog)="processRejectionInput($event)"></reject-reason-dialog>

  <expanded-imageview></expanded-imageview>