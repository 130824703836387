export class ByteArrayCollector {
  constructor(allocByteArray) {
    this.allocByteArray = allocByteArray;
    this.byteLength = 0;
    this.byteArrays = [];
  }
  push(byteArray) {
    this.byteArrays.push(byteArray);
    this.byteLength += byteArray.byteLength;
  }
  flush() {
    if (this.byteArrays.length === 1) {
      const bytes = this.byteArrays[0];
      this.reset();
      return bytes;
    }
    const aggregation = this.allocByteArray(this.byteLength);
    let cursor = 0;
    for (let i = 0; i < this.byteArrays.length; ++i) {
      const bytes = this.byteArrays[i];
      aggregation.set(bytes, cursor);
      cursor += bytes.byteLength;
    }
    this.reset();
    return aggregation;
  }
  reset() {
    this.byteArrays = [];
    this.byteLength = 0;
  }
}